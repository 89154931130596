import { createStore } from 'vuex'

// find all the modules
let modules = {};

// load all routes from core router modules
let context = require.context('@/client/store/modules', true, /\.js/);
context.keys().forEach(key => {
  // name is the routes file name without extension
  let name = key.split('/').pop().replace('.js', '');

  modules[name] = context(key).default
});

// load all store modules from app. store modules with same name as core will override
let appName = process.env.VUE_APP_APPLICATION_NAME;

context = require.context('@/client/applications/', true, /^\.\/.*\/store\/modules.*\.js$/);
context.keys().forEach(key => {
  // filter only the modules for out application
  if (! key.startsWith('./'+appName)) {
    return;
  }
  // name is the routes file name without extension
  let name = key.split('/').pop().replace('.js', '');

  modules[name] = context(key).default
});

// load override modules from override folder
//context = require.context('@/overrides/client/store/modules', true, /\.js/);

context = require.context('@/', true, /\/overrides\/client\/store\/modules\/.*\.js/);

context.keys().forEach(key => {
  // name is the routes file name without extension
  let name = key.split('/').pop().replace('.js', '');

  modules[name] = context(key).default;
});


// store plugins
let plugins = [];
context = require.context('@/client/store/plugins', true, /\.js/);
context.keys().forEach(key => {
  plugins.push(context(key).default);
});

import lodashObject from 'lodash/object';

const genericGetter = (state) => ({path, defaultValue}) => {
  return lodashObject.get(state, path, defaultValue);
}
const genericMutation = (state, {path, value}) => {
  return lodashObject.set(state, path, value);
}
// store plugins
let storeFactory = () => {
  let store = createStore({
    namespaced: true,
    strict: false, // todo: causes issue with async data (data is assigned in store and then modified by component => blow up)
    state:  () => {
      return {};
    },
    mutations: {

    },
    actions: {
    },
  //  modules: modules,
    plugins: plugins,

  });

  store.isRouterSet = false;
  // register the modules, and provide them with the store instance if they are functions
  // default to namespaced modules
  for (const [name, module] of Object.entries(modules)) {
    if (typeof module.namespaced === 'undefined') {
      module.namespaced = true;
    }
  }

  for (const [name, module] of Object.entries(modules)) {
    let finalModule;
    if (typeof module === 'function') {
      finalModule = module(store);
    } else {
      finalModule = module;
    }
    if (typeof finalModule.namespaced === 'undefined') {
      finalModule.namespaced = true;
    }

    // inject magic getter and mutation
    if ( ! finalModule.getters.generic) {
      finalModule.getters.generic = genericGetter;
      finalModule.mutations.generic = genericMutation;

    }
    store.registerModule(name, finalModule);

  }
  return store;
};
export default storeFactory
